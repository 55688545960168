<template>

  <ion-header class="content-header">
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">Kapat</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content-list">
    <div class="site-content">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="billing-form">
                <form action="" class="billingForm" ref="form" id="form" @submit="sendForm">
                  <div class="field-group">
                    <h3></h3>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="title" position="stacked">İlan Başlığı <span class="required">*</span>
                            </ion-label>
                            <ion-input type="text" value="" name="title" id="title" required
                                       v-model="listData.title"/>
                          </ion-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="medias">Fotoğraflar: <span class="required">*</span></ion-label>
                            <input type="file" name="medias" @change="setImage"
                                   style="display: none;"
                                   id="mediaFiles" multiple accept="image/png, image/jpeg, image/jpg"/>
                            <img @click="openFileDialog" v-for="(image,key) in images" :key="key" :src="image"
                                 alt="profile"
                                 style="width: 64px;
                                cursor:pointer;"/>
                            <ion-button @click="openFileDialog">
                              <ion-icon slot="icon-only" :icon="camera"></ion-icon>
                            </ion-button>
                          </ion-item>

                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="content" position="stacked">Açıklama <span class="required">*</span>
                            </ion-label>
                            <ion-textarea id="content" name="content" maxlength="100" required
                                          style="border-bottom: 2px solid #2d2d2d;" v-model="listData.content">
                            </ion-textarea>
                          </ion-item>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="is_sale" position="stacked">Alış/Satış<span class="required">*</span>
                            </ion-label>
                            <ion-select interface="popover" id="is_sale" name="is_sale" :aria-required="true"
                                        :value="getOption('is_sale') ?? true" v-model="listData.is_sale">
                              <ion-select-option :value="true">
                                Satış
                              </ion-select-option>
                              <ion-select-option :value="false">
                                Alış
                              </ion-select-option>
                            </ion-select>
                          </ion-item>
                        </div>
                        <div class="field-input">
                          <ion-label for="is_published">Yayına Al</ion-label>
                          <ion-checkbox style="position: absolute;right: 20px;" color="success" name="is_published"
                                        checked v-model="listData.is_published">
                          </ion-checkbox>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="category_id" position="stacked">Kategori<span class="required">*</span>
                            </ion-label>
                            <ion-select interface="popover" id="category_id" name="category_id"
                                        :aria-required="true" v-model="listData.category_id">
                              <ion-select-option v-for="category in categories" :key="category.id"
                                                 :value="category.id">
                                {{ category.name }}
                              </ion-select-option>
                            </ion-select>
                          </ion-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="field-input">
                          <ion-item>
                            <ion-label for="condition_id" position="stacked">Malzeme Durumu<span
                                class="required">*</span></ion-label>
                            <ion-select interface="popover" id="condition_id" name="condition_id"
                                        :aria-required="true" v-model="listData.condition_id">
                              <ion-select-option v-for="condition in conditions" :key="condition.id"
                                                 :value="condition.id">
                                {{ condition.name }}
                              </ion-select-option>
                            </ion-select>
                          </ion-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="field-input">
                          <ion-label for="location">Lokasyon <span class="required">*</span></ion-label>
                          <GMapMap
                              :center="place.centerData"
                              :zoom="5"
                              map-type-id="terrain"
                              ref="map"
                              :options="mapOptions"
                              style="width: 100%; height: 20rem"
                          >
                            <GMapMarker
                                :position="place.placeData"
                                :clickable="true"
                                :draggable="true"
                                @dragend="markerEvent"
                            />
                          </GMapMap>
                          <ion-input hidden="hidden" name="location" v-model="place.placeDataForm"
                                     required></ion-input>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="price" position="stacked">
                                  Fiyat <span class="required">*</span>
                                </ion-label>
                                <ion-input type="number" value="" name="price" id="price" required
                                           v-model="listData.price"/>
                              </ion-item>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="price_fraction" position="stacked">
                                  Kuruş <span class="required">*</span>
                                </ion-label>
                                <ion-input type="number" value="00" name="price_fraction" id="price_fraction"
                                           required v-model="listData.price_fraction"/>
                              </ion-item>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="price_type_id" position="stacked">Fiyat Tipi<span
                                    class="required">*</span></ion-label>
                                <ion-select interface="popover" id="price_type_id" name="price_type_id"
                                            :aria-required="true" v-model="listData.price_type_id">
                                  <ion-select-option v-for="priceType in priceTypes" :key="priceType.id"
                                                     :value="priceType.id">
                                    {{ priceType.symbol }}
                                  </ion-select-option>
                                </ion-select>
                              </ion-item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="tonnage" position="stacked">
                                  Ağırlık <span class="required">*</span>
                                </ion-label>
                                <ion-input type="number" value="" name="tonnage" id="tonnage" required
                                           v-model="listData.tonnage"/>
                              </ion-item>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="tonnage_fraction" position="stacked">
                                  Küsürat <span class="required">*</span>
                                </ion-label>
                                <ion-input type="number" value="00" name="tonnage_fraction" id="tonnage_fraction"
                                           required v-model="listData.tonnage_fraction"/>
                              </ion-item>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="field-input">
                              <ion-item>
                                <ion-label for="tonnage_type_id" position="stacked">Ağırlık Tipi<span
                                    class="required">*</span></ion-label>
                                <ion-select interface="popover" id="tonnage_type_id" name="tonnage_type_id"
                                            :aria-required="true" v-model="listData.tonnage_type_id">
                                  <ion-select-option v-for="tonnageType in tonnageTypes" :key="tonnageType.id"
                                                     :value="tonnageType.id">
                                    {{ tonnageType.symbol }}
                                  </ion-select-option>
                                </ion-select>
                              </ion-item>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div><!-- .field-group -->
                </form><!-- .billingForm -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer style="position:fixed;z-index: 1000000000000000;bottom: 0;">
    <ion-toolbar>
      <button form="form" slot="end" style="background: transparent;">
        <ion-button type="submit">{{ isNew === true ? 'Kaydet' : 'Düzenle' }}</ion-button>
      </button>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonButton,
  IonButtons, IonCheckbox,
  IonContent, IonFooter,
  IonHeader, IonIcon,
  IonInput, IonItem,
  IonLabel, IonSelect, IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {inject, ref} from "@vue/runtime-core";
import {camera} from "ionicons/icons";

export default {
  name: "CreateList",
  props: {
    title: {type: String, default: ''},
    list: {
      type: Object, default: () => {
        return {
          category_id: null,
          city_id: null,
          condition_id: null,
          content: null,
          first_media: null,
          medias: [],
          id: null,
          is_published: false,
          is_sale: null,
          price: null,
          price_type_id: null,
          price_fraction: null,
          tonnage: null,
          tonnage_type_id: null,
          tonnage_fraction: null,
          location: null,
          title: null,
        }
      }
    },
    isNew: {type: Boolean, default: true},
  },
  components: {
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonLabel,
    IonInput,
    IonFooter,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonItem,
    IonIcon,
    IonCheckbox,
  },
  setup() {
    const $api = inject('api');
    const mapOptions = ref({
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8ec3b9"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1a3646"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#64779e"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#334e87"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6f9ba5"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#3C7680"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#304a7d"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2c6675"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#255763"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#b0d5ce"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#3a4762"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#0e1626"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4e6d70"
            }
          ]
        }
      ]
    });
    const place = ref({
      centerData: {lat: 39.94538951, lng: 32.94426233},
      placeData: {lat: 39.94538951, lng: 32.94426233},
      placeDataForm: '39.94538951, 32.94426233',
    });
    place.value.centerData = {lng: $api.auth?.location?.coordinates[0], lat: $api.auth?.location?.coordinates[1]};
    place.value.placeData = {lng: $api.auth?.location?.coordinates[0], lat: $api.auth?.location?.coordinates[1]};
    place.value.placeDataForm = $api.auth?.location?.coordinates[0] + ', ' + $api.auth?.location?.coordinates[1];
    return {mapOptions, camera, place};
  },
  data() {
    return {
      listData: {
        category_id: null,
        condition_id: null,
        is_sale: true,
        title: null,
        content: null,
        tonnage_type_id: null,
        tonnage_fraction: null,
        tonnage: null,
        price_type_id: null,
        price_fraction: null,
        price: null,
      },
      categories: this.$api.categories,
      conditions: this.$api.conditions,
      priceTypes: this.$api.priceTypes,
      tonnageTypes: this.$api.tonnageTypes,
      images: [],
      files: null,
    }
  },
  mounted() {
    let _this = this
    if (this.isNew === false) {
      this.listData = JSON.parse(JSON.stringify(this.list));
      this.listData.category_id = parseInt(this.listData.category_id);
      this.listData.condition_id = parseInt(this.listData.condition_id);
      this.listData.price_type_id = parseInt(this.listData.price_type_id);
      this.listData.tonnage_type_id = parseInt(this.listData.tonnage_type_id);
      this.listData.price = parseFloat(this.listData.price) - (parseInt(this.listData.price_fraction) / 100 );
      this.listData.tonnage = parseFloat(this.listData.tonnage) - (parseInt(this.listData.tonnage_fraction) / 100 );
      this.listData.medias.forEach(async function (media) {
        _this.images.push(media);
      });
      const data = {lat: parseFloat(this.listData.location.coordinates[1]), lng: parseFloat(this.listData.location.coordinates[0])};
      this.place.centerData = data;
      this.place.placeData = data;
      this.place.placeDataForm = `${this.listData.location.coordinates[0]}, ${this.listData.location.coordinates[1]}`;
    }
  },
  methods: {
    openFileDialog() {
      document.getElementById("mediaFiles").click();
    },
    setImage: function (e) {
      let files = e.target.files;
      this.files = files;
      if (files.length > 4) {
        this.$helper.setAlert('Hata', '', 'En fazla 4 adet dosya yükleyebilirsiniz.').then(alert => alert.present());
        e.target.val = '';
        return;
      }
      let blobUrls = [];
      files.forEach(function (file) {
        const blob = new Blob([file])
        blobUrls.push(URL.createObjectURL(blob));
      })
      this.images = blobUrls;
    },
    dismissModal: function () {
      this.$emitter.emit('dismissModal')
      this.close();
    },
    getOption: function (optionName) {
      let val = Object.keys(this.list).filter((key) =>
          this.list[key] === optionName);
      return this.list[val];
    },
    sendForm: function (e) {
      e.preventDefault();
      const formData = new FormData(this.$refs.form);
      let _this = this;

      const formDataObj = {};
      formData.forEach((value, key) => (formDataObj[key] = value));
      formDataObj.medias = this.files;
      if (formDataObj.is_published === '') {
        formDataObj.is_published = 'off';
      }
      if (this.isNew) {
        return this.$api.post('/posts', formDataObj, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                let query = _this.$api.Model
                    .custom('my-posts')
                    .page(1);
                _this.$helper.setAlert(
                    'İlan yükleme başarılı',
                    'İlanınız yüklendi',
                    'İlanınız onaylı kullanıcılar tarafından onaylanana kadar beklemede kalacaktır!'
                ).then(alert => alert.present());
                _this.$emitter.emit('newPost', query);
              }
            });
      }
      let edit = false;
      this.$helper.setAlert('Düzenlemek istediğinizden Emin Misiniz?', '', 'Düzenleme yaparsanız ilanınız tekrar onay sürecine girecektir.',
          [{
            text: 'Evet', handler: () => {
              edit = true;
            }
          }, {text: 'Hayır'}]
      ).then(async alert => {
        await alert.present();
        await alert.onDidDismiss();
        if (edit) {
          this.$api.post('/posts/' + this.list.slug, formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .then((response) => {
                if (response.status === 200 || response.status === 201) {
                  let query = _this.$api.Model
                      .custom('my-posts')
                      .page(1);
                  _this.$helper.setAlert(
                      'İlan düzenlemeniz başarılı',
                      'İlanınız yüklendi',
                      'İlanınız onaylı kullanıcılar tarafından onaylanana kadar beklemede kalacaktır!'
                  ).then(alert => alert.present());
                  _this.$emitter.emit('newPost', query);
                }

              });
        }
      });
    },
    markerEvent: function (e) {
      const data = {lat: parseFloat(e.latLng.lat().toFixed(8)), lng: parseFloat(e.latLng.lng().toFixed(8))};
      this.place.centerData = data;
      this.place.placeData = data;
      this.place.placeDataForm = `${e.latLng.lat().toFixed(8)}, ${e.latLng.lng().toFixed(8)}`;
    },
  },
}
</script>

<style scoped>
@media only screen and (min-width: 600px) {
  .content-header{
    height: 6vh;
  }
  .content-list {
    height: 53vh;
  }

}
@media only screen and (max-width: 600px) {
  .content-list {
    height: 80vh;
  }
}
</style>