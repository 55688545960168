<template>
  <div class="container">

    <div class="more-filters">
      <div class="flex">
        <label for="category">Kategori: </label>
        <ion-select @ionChange="setCategory" id="category" interface="alert" cancel-text="İptal" ok-text="Onayla" name="category"
                    :value="category">
          <ion-select-option value="*">
            Hepsi
          </ion-select-option>
          <ion-select-option v-for="category in $api.categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </ion-select-option>
        </ion-select>
      </div>
      <div class="flex">
        <label for="type">İlan Tipi: </label>
        <ion-select @ionChange="setType" interface="alert" cancel-text="İptal" ok-text="Onayla" id="type" name="type" :value="type">
          <ion-select-option value="*">
            Hepsi
          </ion-select-option>
          <ion-select-option value="0">
            Alış
          </ion-select-option>
          <ion-select-option value="1">
            Satış
          </ion-select-option>
        </ion-select>
      </div>
      <div class="flex">
        <ion-button v-if="filtered" color="light" size="small" @click="setFilter">
          Seçimi Temizle
        </ion-button>

      </div>
    </div>
  </div>

</template>

<script>

import {IonButton, IonSelect, IonSelectOption} from "@ionic/vue";

export default {
  name: "FilterComponent",
  components: {IonSelect, IonSelectOption, IonButton},
  mounted() {
    let _this = this;
    this.$emitter.on('setFiltered', (filtered) => _this.filtered = filtered );
  },
  data(){
    return {
      type: '*',
      category: '*',
      filtered: false,
    };
  },
  methods: {
    setCategory: function (event) {
      this.category = event.detail.value;
      this.$emitter.emit('setCategory',event.detail.value);
    },
    setFilter: function () {
      this.$emitter.emit('resetFilter');
      this.type = '*';
      this.category = '*';
      this.filtered = false;
    },
    setType: function (event) {
      this.type = event.detail.value;
      this.$emitter.emit('setType',event.detail.value);
    }
  },
}
</script>

<style scoped>
.select-box .flex {
  border-right: 10px black;
}
@media screen and (min-width: 600px) {
  .more-filters{
    flex-direction: row;
  }
}
@media screen and (max-width: 601px) {
  .more-filters{
    flex-direction: column;
  }
}
.more-filters {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.flex {
  align-items: center;
}
.flex > label {
  font-weight: bolder;
}
.container {
  margin-top: 25px
}
</style>