<template>
  <ion-page>
    <ion-content>
      <paid-component/>
      <slider-component v-if="$router.currentRoute.value.name === 'list'"/>
      <filter-component v-if="$router.currentRoute.value.name === 'list'"/>
      <popular-component v-model:listData="listData"/>
    </ion-content>
  </ion-page>
</template>

<script>
import {defineComponent} from 'vue';
import SliderComponent from "../components/SliderComponent";
import PopularComponent from "../components/PopularComponent";
import FilterComponent from "../components/FilterComponent";
import {IonPage, IonContent} from "@ionic/vue";
import PaidComponent from "../components/PaidComponent.vue";

export default defineComponent({
  name: 'Listing',
  data() {
    return {
      listData: {},
      isFav: false,
    }
  },
  mounted() {
    let id = this.$router.currentRoute.value.params.id;
    let _this = this;
    if (id) {
      this.$api.get('posts/'+id).then(
          (res) => _this.listData = res.data.data
      );
    }
  },
  components: {
    PaidComponent,
    IonPage,
    IonContent,
    FilterComponent,
    SliderComponent,
    PopularComponent,

  }
});
</script>

<style scoped>

</style>