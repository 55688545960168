<template>

  <ion-header :class="isMine ? 'content-header-ex' :'content-header'">
    <ion-toolbar>
      <ion-title>
        <h1>{{ listData.title }}</h1>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">Kapat</ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar v-if="isMine" style="box-shadow: 0 -1px 0px 1px black;">
      <ion-buttons style="justify-content: end">
        <ion-button color="danger" v-if="isMine" @click="deletePost()">Sil</ion-button>
        <ion-button color="warning" v-if="isMine" @click="edit()">Düzenle</ion-button>
        <ion-button color="danger" v-if="isMine" @click="publish()">
          {{ listData.is_published ? 'Yayından Kaldır' : 'Yayına Al' }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content :class="isMine ? 'content-list-ex' :'content-list'">
    <div class="container" style="overflow-y: auto">
      <div class="row">
        <div class="col-lg-12">
          <div class="hightlight-grid">
            <div class="container">
              <div class="row" style="
                   margin-top: 10px;">
                <div class="col-sm-12 col-lg-6">
                  <div class="flex" style="display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;">
                    <div>
                      <b>Fiyat: </b> {{ listData.price }}
                      {{ listData.priceType.symbol }}<br/>
                      <b>Miktar: </b>{{ listData.tonnage }}
                      {{ listData.tonnageType.symbol }} ({{ listData.tonnageType.name }})<br/>
                      <b>Tip: </b> {{ listData.is_sale === true ? 'Satış' : 'Alış' }}
                    </div>
                    <div class="flex"
                         style="flex-direction: column;justify-content: center;align-items: center;align-content: center;cursor: pointer"
                         @click="setFav()">
                      <ion-icon style="width: 32px;height:32px;color: green;" :icon="setStar()"></ion-icon>
                      {{ listData.isFav ? 'Favorilendi' : 'Favorile' }}
                    </div>
                  </div>
                  <div class="place__box place__box-overview">
                    <h3>Açıklama</h3>
                    <div class="place__desc">
                      {{ listData.content }}
                    </div><!-- .place__desc -->
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <Slider :images="listData.medias"></Slider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="shop-details__tabs">
                    <ul class="shop-details__tablist tabs">
                      <li class="active"><a title="Description">Satıcı</a></li>
                    </ul>
                    <div class="shop-details__panel" id="">
                      <User title="Satıcı" :user="listData.publisherUser"></User>
                    </div>

                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="shop-details__tabs">
                    <ul class="shop-details__tablist tabs">
                      <li class="active"><a title="Description">Genel Bilgiler</a></li>
                    </ul>
                    <div class="shop-details__panel" id="panel_description">
                      <table class="table">
                        <tbody>
                        <tr>
                          <td>Kategori</td>
                          <td>{{ listData.category.name }}</td>
                        </tr>
                        <tr>
                          <td>Durum</td>
                          <td>{{ listData.condition.name }}</td>
                        </tr>
                        <tr>
                          <td>Miktar</td>
                          <td>{{ listData.tonnage }} {{ listData.tonnageType.symbol }} ({{
                              listData.tonnageType.name
                            }})
                          </td>
                        </tr>
                        <tr>
                          <td>Lokasyon</td>
                          <td>{{ listData.city }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/vue";
import User from "../components/User.vue";
import Slider from "../components/Slider.vue";
import {star, starOutline} from "ionicons/icons";

export default {
  name: "ProductPage",
  props: {
    title: {type: String, default: ''},
    list: {
      type: Object
    },
    isUrl: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      listData: this.list,
      isMine: this.$props.list.publisherUser.id === this.$api.auth?.id
    }
  },
  components: {Slider, User, IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonContent, IonIcon},
  mounted() {
  },
  methods: {
    publish() {
      let _this = this;
      this.$api.post('posts/' + this.list.slug + '/publish').then(function (response) {
        if (response.status === 200) {
          _this.listData = response.data.data;
          _this.$emitter.emit('resetPost', {slug: _this.listData.slug, post: _this.listData});
        }
      });
    },
    deletePost() {
      let _this = this;
      this.$api.delete('posts/' + this.list.slug).then(function (response) {
        if (response.status === 200) {
          _this.$emitter.emit('removePost', _this.listData.slug);
        }
      });
    },
    edit() {
      global.emitter.emit('test');
      this.$emitter.emit('editPost',this.listData);
    },
    setFav() {
      let _this = this;
      this.$api.post('posts/' + this.list.slug + '/fav').then(
          (res) => {
            if (res.status === 200) {
              _this.list.isFav = !_this.list.isFav;
              if (!_this.list.isFav) {
                _this.$emitter.emit('removeFav', _this.list.slug);
              }
            }

          }
      )
    },
    setStar() {
      return this.list.isFav ? star : starOutline;
    },
    dismissModal: function () {
      this.$emitter.emit('dismissModal')
      if (this.isUrl) {
        this.$emitter.emit('refreshSite')
      }
    }
  },
}
</script>

<style scoped>

@media only screen and (min-width: 600px) {
  .content-header{
    height: 6vh;
  }
  .content-header-ex{
    height: 12vh;
  }
  .content-list {
    height: 59vh;
  }
  .content-list-ex {
    height: 53vh;
  }

}
@media only screen and (max-width: 600px) {
  .content-list {
    height: 93vh;
  }
  .content-list-ex {
    height: 84vh;
  }
}
</style>