<template>
  <section class="restaurant-wrap">
    <div class="container">
      <div class="row"
           :style="$router.currentRoute.value.name === 'my-list'
           ? 'justify-content:space-between;margin-bottom: 50px;'
           : 'margin-bottom: 50px;'"
           v-if="title">
        <h1>
          {{ title }}
        </h1>
        <ion-button @click="createList" color="success" v-if="$router.currentRoute.value.name === 'my-list'">
          İlan Ekle
        </ion-button>
      </div>
      <div class="row" v-if="lists.length !== 0 && query">
        <div class="col-12 col-sm-4 ion-margin-bottom" v-for="(list,index) in lists" v-bind:key="index">
          <div class="place-item layout-02 place-hover" data-maps_name="mattone_restaurant"
               style="width: 100%; display: inline-block;">
            <div class="place-inner">
              <div class="place-thumb hover-img">
                <a class="entry-thumb" style="cursor: pointer" @click="onClickList(list)" tabindex="0">
                  <img
                      :src="list.first_media ?? image()"
                      :style="list.first_media === null ? 'background-color:white;width:unset;margin:0 auto;display:block;' : ''"
                      alt="">
                </a>


                <a style="cursor: pointer" @click="onClickUser(list.publisherUser)" class="author"
                   title="Author" tabindex="0">
                  <img :src="list.publisherUser.avatar ?? person()"
                       :style="list.publisherUser.avatar === null ? 'background-color:white;' : ''" alt="Author">
                </a>
              </div>
              <div class="entry-detail">
                <div class="entry-head">
                  <div class="place-type list-item">
                    <span>{{ list.category.name }}</span>
                  </div>
                  <div class="place-city">
                    <a style="cursor: pointer" @click="onClickList(list)" tabindex="0">{{ list.city }}</a>
                  </div>
                </div>
                <h3 class="place-title" style="margin-bottom: 0">
                  <a style="cursor: pointer" @click="onClickList(list)" tabindex="0">{{ list.title }}</a>
                </h3>
                {{ list.is_sale === true ? 'Satış' : 'Alış' }}

                <div class="open-now" style="margin-top: 30px;">
                  <i class="las la-door-open"></i>
                  <a style="cursor: pointer" @click="onClickList(list)"
                     v-if="$router.currentRoute.value.name !== 'my-list'">
                    İlan Detayını Gör
                  </a>
                  <a style="cursor: pointer" @click="onClickList(list)" v-else>
                    {{ list.isApproved ? 'Onaylandı' : 'Onay bekliyor' }} ve
                    {{
                      list.is_published ? (list.isApproved ? 'yayında' : 'yayınlanacak') : (list.isApproved ? 'yayında Değil' : 'taslak kalacak')
                    }}
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else-if="searchIsEmpty">
        Maalesef Aradığınız ilan bulunamadı.
      </div>
      <div class="row" v-else-if="lists.length === 0">
        <div class="col-12 col-sm-4 ion-margin-bottom" v-for="number in getRange(perPage-12)" :key="number">
          <div class="place-item layout-02 place-hover" data-maps_name="mattone_restaurant"
               style="width: 100%; display: inline-block;">
            <div class="place-inner">
              <div class="place-thumb hover-img">
                <a class="entry-thumb" tabindex="0">
                  <ion-skeleton-text animated style="width: 100%;height: 210px;"></ion-skeleton-text>
                </a>


                <a class="author" title="Author" tabindex="0">
                  <ion-skeleton-text animated></ion-skeleton-text>
                </a>
              </div>
              <div class="entry-detail">
                <div class="entry-head" style="width: 100%;">
                  <div class="place-type list-item" style="min-width: 40%;">
                    <span><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></span>
                  </div>
                  <div class="place-city" style="min-width: 40%;">
                    <a tabindex="0">
                      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                    </a>
                  </div>
                </div>
                <h3 class="place-title">
                  <a tabindex="0">
                    <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                  </a>
                </h3>
                <div class="open-now">
                  <i class="las la-door-open"></i><a style="min-width: 100%;">
                  <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                </a>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row" style="justify-content: center;" v-if="query && lists.length !== 0">
        <ion-button @click="loadNextPage()" class="white" color="light" shape="round"
                    :disabled="nothingLeft">
          {{ nothingLeft ? 'En alta indin' : 'Daha fazla' }}
          <ion-icon slot="icon-only" :icon="eyeOutline()" color="dark"></ion-icon>
        </ion-button>
      </div>
    </div>
  </section>
  <ion-modal :isOpen="isUserOpen" :backdrop-dismiss="false">
    <UserPage title="Kullanıcı" :user="user"/>

  </ion-modal>
  <ion-modal :isOpen="isEditOpen" :backdrop-dismiss="false">
    <CreateList :title="'İlan Düzenle'" :isNew="false" :list="selectedList"/>

  </ion-modal>
  <ion-modal :isOpen="isCreateOpen" :backdrop-dismiss="false">
    <CreateList :title="'İlan Ekle'" :isNew="true"/>

  </ion-modal>
  <ion-modal :isOpen="isListOpen" :backdrop-dismiss="false">
    <ProductPage title="İlan" :list="selectedList" :isUrl="isUrl"/>
  </ion-modal>
</template>

<script>
import {eyeOutline, image, list, person} from "ionicons/icons";
import {IonButton, IonIcon, IonModal, IonSkeletonText} from "@ionic/vue";
import ProductPage from "../components/ProductPage.vue";
import UserPage from "../components/UserPage.vue";
import CreateList from "../components/CreateList.vue";

export default {
  name: 'PopularComponent',
  components: {
    IonModal,
    IonIcon, IonButton,
    IonSkeletonText,
    ProductPage,
    CreateList,
    UserPage
  },
  props: [
    'listData'
  ],
  beforeUnmount() {
    let _this = this;
    _this.isListOpen = false;
    _this.isUserOpen = false;
    _this.isCreateOpen = false;
    _this.isEditOpen = false;
    this.$nextTick(() => {
      _this.isListOpen = false;
      _this.isUserOpen = false;
      _this.isCreateOpen = false;
      _this.isEditOpen = false;
    })
  },
  data() {
    return {
      perPage: 15,
      nothingLeft: false,
      isListOpen: false,
      isCreateOpen: false,
      isUserOpen: false,
      isEditOpen: false,
      isUrl: null,
      selectedList: null,
      user: null,
      page: 0,
      lists: [],
      query: null,
      title: null,
      queryModel: 'posts',
      searchIsEmpty: false,
    }
  },
  watch: {
    listData: function (newVal) {
      let _this = this;
      if (newVal?.title) {
        this.onClickList(newVal, true);
        this.$emitter.on('refreshSite', function () {
          _this.$router.push('/list');
        });
      }
    }
  },
  mounted() {
    let _this = this;
    if (this.$router.currentRoute.value.name === 'favorite-list') {
      this.title = 'Favorilerin';
      this.queryModel = 'favs';
      this.query = this.$api.Model
          .custom(this.queryModel)
          .page(this.page)
    }
    if (this.$router.currentRoute.value.name === 'my-list') {
      this.title = 'İlanlarım';
      this.queryModel = 'my-posts';
      this.query = this.$api.Model
          .custom(this.queryModel)
          .page(this.page)
    }
    this.$emitter.on('removeFav', function (slug) {
      if (_this.$router.currentRoute.value.name === 'favorite-list') {
        _this.isListOpen = false;
        _this.lists = _this.lists.filter((list) => list.slug !== slug);
        _this.searchIsEmpty = _this.lists.length === 0;
      }
    });
    this.$emitter.on('editPost', async function (list) {
      _this.isListOpen = false;
      _this.isEditOpen = true;
      _this.selectedList = list;
      _this.isNew = false;
    })
    this.$emitter.on('newPost', function (query) {
      _this.isCreateOpen = false;
      _this.query = query;
      _this.lists = [];
      _this.loadNextPage();
    })
    this.$emitter.on('resetPost', function ({slug, post}) {
      _this.lists = _this.lists.map((list) => {
        if (list.slug === slug) {
          return post;
        }
        return list;
      });
    });
    this.$emitter.on('removePost', function (slug) {
      _this.isListOpen = false;
      _this.lists = _this.lists.filter((list) => list.slug !== slug);
      _this.searchIsEmpty = _this.lists.length === 0;
    });
    this.$emitter.on('resetFilter', function () {
      _this.lists = [];
      _this.searchIsEmpty = false;
      _this.page = 1;
    });
    this.$emitter.on('setPosts', function (posts) {
      _this.searchIsEmpty = posts.length === 0;
      _this.lists = posts;
    });
    this.$emitter.on('dismissModal', function () {
      _this.isListOpen = false;
      _this.isUserOpen = false;
      _this.isCreateOpen = false;
      _this.isEditOpen = false;
    });
    this.$emitter.on('setPostQuery', function (query) {
      _this.query = query;
    });
    this.loadNextPage();
  },
  methods: {
    createList() {
      this.isCreateOpen = true;
      this.isNew = true;
    },
    image() {
      return image;
    },
    onClickUser(user) {
      this.user = user;
      this.isUserOpen = true;
    },
    onClickList(list, isUrl = false) {
      this.isUrl = isUrl;
      this.selectedList = list;
      this.isListOpen = true;
    },
    person() {
      return person
    },
    eyeOutline() {
      return eyeOutline
    },
    getRange(count) {
      return Array.from({length: count}, (_, index) => index);
    },
    loadNextPage() {
      let _this = this;
      this.page = this.page + 1;
      if (!this.query) {
        this.query = this.$api.Model
            .custom(this.queryModel)
            .page(this.page);
      }
      this.query.page(this.page).get().then(
          (postsData) => {
            postsData.links.next === null ? _this.nothingLeft = true : _this.nothingLeft = false;
            _this.lists = _this.lists.concat(postsData.data);
            _this.searchIsEmpty = _this.lists.length === 0;
          }
      );
    },
    list() {
      return list
    }

  }
}
</script>
