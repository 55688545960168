<template>
  <div class="card">
    <div class="card-header">
      <img style="position:absolute;right: 1.25rem;
    width: 32px;
    height: 32px;" :src="user.avatar  ?? person()"
           class="rounded minimal">
      <h2 class="card-title">Adı : {{ user.name }}</h2>
      <h4 class="card-title">Şirket Adı : {{ user.company_name }}</h4>
      <p class="card-text">Çalışma Şekli : {{ user.workType.name }} </p>
    </div>
    <div style="    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;" v-if="!auth">
      <ion-button @click="login" style="min-width: 100%;">
        <ion-icon :icon="person()"></ion-icon>
        &nbsp;Giriş yap
      </ion-button>
      <ion-button @click="login" style="min-width: 100%;">
        <ion-icon :icon="logInOutline()"></ion-icon>
        &nbsp;Kayıt Ol
      </ion-button>
    </div>
    <div class="card-body" v-else-if="user.id !== auth?.id">
      <div v-if="!auth?.isVip" style="text-align: center;">
        Kullanıcıyı aramak için
        <ion-button @click="premium" style="min-width: 100%;">
          <ion-icon :icon="diamondSharp()"></ion-icon>
          &nbsp;Premium olunuz
        </ion-button>
        {{ auth?.hasFirstMessage ? 'yada tek seferlik' : 'maalesef tek seferlik mesaj hakkınızı kullandınız.' }}
        <ion-button @click="message" style="min-width: 100%;" v-if="auth?.hasFirstMessage">
          <ion-icon :icon="mail()"></ion-icon>&nbsp;Mesaj At
        </ion-button>
      </div>

      <div style="    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;" v-else>
        <ion-button @click="message" style="min-width: 100%;">
          <ion-icon :icon="mail()"></ion-icon>
          &nbsp;Mesaj At
        </ion-button>
        <ion-button @click="phone" style="min-width: 100%;">
          <ion-icon :icon="call()"></ion-icon>
          &nbsp;Ara
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import {IonButton, IonIcon, loadingController} from "@ionic/vue";
import {
  call,
  diamondSharp, logInOutline,
  mail,
  personOutline as person
} from "ionicons/icons";
import Profile from "../views/Profile.vue";

export default {
  name: "User",
  mounted() {
    this.$emitter.on('updateProfile', (profile) => {
      profile.then((profileData) => {
        this.auth = profileData;
      });
    })
  },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      auth: this.$api.auth
    }
  },
  components: {IonIcon, IonButton},
  methods: {
    login(){
      this.$router.push('/login');
    },
    logInOutline() {
      return logInOutline
    },
    mail() {
      return mail
    },
    diamondSharp() {
      return diamondSharp
    },
    profile() {
      return Profile
    },
    premium() {
      this.$emitter.emit('calling_vip')
    },
    async message() {
      let messageTitle = '';
      let messageInput = '';
      let send = false;
      return await this.$helper.setAlert('Mesaj Göndermek istediğinize emin misiniz?', 'Eminseniz lütfen mesaj bilgilerini giriniz.', '',
          [{
            text: 'Evet Gönder!', role: 'submit', handler: data => {
              if (data.title.trim() && data.message.trim()) {
                send = true;
                messageInput = data.message;
                messageTitle = data.title;
                return true;
              }
              return false;
            }
          }, {text: 'Hayır'}],
          {
            inputs: [
              {
                name: 'title',
                placeholder: 'Mesaj Başlığı',
              },
              {
                name: 'message',
                type: 'textarea',
                placeholder: 'Mesaj',
              },
            ],
          }
      ).then(async alert => {
        await alert.present();
        await alert.onDidDismiss();
        if (send) {
          const loading = await loadingController
              .create({
                message: 'Lütfen bekleyiniz...',
                duration: 0,
              });
          await loading.present();
          return await this.$api.post('messages', {
            subject: messageTitle,
            recipient: this.user.id,
            message: messageInput,
          })
              .finally(() => {
                    loading.dismiss()
                  }
              );
        }
      });

    },
    async phone() {
      const loading = await loadingController
          .create({
            message: 'Santral numarası size yönlendiriliyor lütfen bekleyiniz...',
            duration: 0,
          });
      await loading.present();
      await this.$api.post('user/' + this.user.id + '/phone', {}, {}, false)
          .then((data) => {
                loading.dismiss();
                this.$helper.setAlert('Konuşmak için santral numarayı belirledi',
                    'Arandığınız Numara: ' + data.data.phone,
                    'Numara ile görüşmeniz bitmeden bu ekranı kapatmayınız.', [])
                    .then(async alert => {
                      await alert.present();
                      await alert.onDidDismiss();
                      return await this.$api.post('user/' + this.user.id + '/phone', {dc: true})
                          .finally(() => {
                                loading.dismiss()
                              }
                          );
                    });
              }
          )
          .catch(() => {
            loading.dismiss()
            this.$helper.setAlert('Hata oluştu!',
                'Sanıyorum sizi aradık fakat ulaşamadık.',
                'Eğer sizi aradığımızı düşünmüyorsanız, önce profilinizde bulunan telefon numaranınız kontrol edin doğruysa lütfen müşteri hizmetlerini arayınız. Müş. Hiz. : +90 850 532 9942 ', [])
                .then(async alert => {
                  await alert.present();
                });

          });

    },
    call() {
      return call
    },
    person() {
      return person
    },
    dismissModal: function () {
      this.$emitter.emit('dismissUserModal')
    }
  },
}
</script>

<style scoped>

.minimal {
  height: 15%;
  width: 15%;
}

</style>