<template>
  <div>
    <img style="max-width: 100%;" :src="images[currentSlide]" alt="İlan Ana Resim">
    <div v-if="images.length !== 1" style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;">
      <img style="max-height: 60px;" v-for="(image,key) in images" :key="key" @click="slideTo(key)" :src="image" :alt="'İlan Resmi '+ (1+key)"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Slider',
  props: [
      'images'
  ],
  components: {
  },
  data: () => ({
    currentSlide: 0,
  }),
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
  },
})
</script>