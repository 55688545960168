<template>

  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">Kapat</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content style="height: 93vh">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="shop-details__tabs">
            <div class="shop-details__panel" id="">
              <User :user="user"></User>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ion-content>
</template>

<script>
import {IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar} from "@ionic/vue";
import User from "../components/User.vue";

export default {
  name: "UserPage",
  props: {
    title: {type: String, default: ''},
    user: {
      type: Object
    }
  },
  components: {IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonContent, User},
  methods: {
    dismissModal: function () {
      this.$emitter.emit('dismissModal')
    }
  },
}
</script>

<style scoped>
</style>