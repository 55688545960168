<template>
  <div class="site-banner" style="background-image: url(assets/bg.png);background-position: right 30px bottom;
    background-size: 58vw;
}">
    <div class="container">
      <div class="site-banner__content">
        <h1 class="site-banner__title" style="color: white; font-weight: bolder">İlan Arama</h1>
        <form @submit="searchForm" class="site-banner__search layout-02" id="searchForm">
          <div class="field-input">
            <label for="search" style=" font-weight: bolder; color: black;">Arama: </label>
            <input class="site-banner__search__input" id="search" v-model="searchData"
                   form="searchForm"
                   type="text" name="search" placeholder="Ör: İnşaat atığı..." autocomplete="off" maxlength="20">
          </div><!-- .site-banner__search__input -->
          <div class="field-submit">
            <button ref="button"
                    id="searchButton"
                    form="searchForm">
              <ion-icon :icon="searchSharp" size="large"/>
            </button>
          </div>
        </form><!-- .site-banner__search -->
        <div class="filters">
          <div class="flex" style="line-height: inherit; color: white; max-width: 50%; margin: auto;">
            <label for="city">Nerede: </label>
            <ion-select
                id="city"
                interface="alert"
                cancel-text="İptal"
                ok-text="Onayla"
                name="city"
                v-model="cityData"
            >
              <ion-select-option value="*">
                Hepsi
              </ion-select-option>
              <ion-select-option v-for="city in $api.cities" :key="city.id" :value="city.id">
                {{ city.name }}
              </ion-select-option>
            </ion-select>
          </div>
        </div><!-- .site-banner__search__input -->
        <p class="site-banner__meta" style="color: white;">
          <span style="color: white;">Popüler Aramalar:</span>
          <a title="Demir" @click="setSearch('Demir')" style="color: white; cursor: pointer;">Demir</a>
          <a title="Pimaş" @click="setSearch('Pimaş')" style="color: white; cursor: pointer;">Pimaş</a>
          <a title="Pirinç" @click="setSearch('Pirinç')" style="color: white; cursor: pointer;">Pirinç</a>
        </p><!-- .site-banner__meta -->

      </div><!-- .site-banner__content -->
    </div>
  </div>


</template>
<style scoped>


.flex {
  align-items: center;
}

.flex > label {
  font-weight: bolder;
}

.flex > .ion-select {
  display: none;
}

@media only screen and (max-width: 600px) {

  .field-input {
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 10px !important;
    flex: unset !important;
    max-width: unset !important;
  }

  ion-select#city {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 601px) {
  .filters {
  max-width: 500px;
  }

  .field-input {
  }

  ion-select#city {
  }
}

.flex {
  margin-left: 10px;
}

.field-submit {
  align-self: center;
}
</style>
<script>
import {searchSharp} from 'ionicons/icons';
import {IonIcon, IonSelect, IonSelectOption} from "@ionic/vue";

export default {
  name: "SliderComponent.vue",
  components: {IonSelect, IonSelectOption, IonIcon},
  setup() {
    return {
      searchSharp
    };
  },
  data() {
    return {
      searchData: '',
      cityData: '*',
      category: '*',
      type: '*',
      searchFilter: null,
    };
  },
  mounted() {
    let _this = this;
    this.$emitter.on('setCategory', function (category) {
      _this.category = category;
      _this.searchForm();
    });
    this.$emitter.on('setType', function (type) {
      _this.type = type;
      _this.searchForm();
    });
    this.$emitter.on('resetFilter', function () {
      _this.searchData = '';
      _this.cityData = '*';
      _this.category = '*';
      _this.type = '*';
      _this.searchFilter = null;
      document.getElementById('searchButton').click();
    });

  },
  methods: {
    setSearch: function (search) {
      this.searchData = search;
    },
    searchForm: function (event = null) {
      let _this = this;
      if (event) {
        event.preventDefault();
      }
      this.searchFilter =
          this.$api.Model.custom('search')
              .where('title', this.searchData)
              .where('city', this.cityData === '*' ? null : this.cityData)
              .where('category', this.category === '*' ? null : this.category)
              .where('type', this.type === '*' ? null : this.type);
      this.searchFilter.page(1).get().then((res) => {
        _this.$emitter.emit('setPosts', res.data);
        if (_this.searchData !== '') {
          _this.$emitter.emit('setFiltered', true);
        }
        _this.$emitter.emit('setPostQuery', this.searchFilter);
      });
    }
  }
}
</script>